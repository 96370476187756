// toSorted
if (Array.prototype.toSorted === undefined)
  Array.prototype.toSorted = function (...args) {
    return this.slice(0).sort(...args);
  };

// toReversed
if (Array.prototype.toReversed === undefined)
  Array.prototype.toReversed = function (...args) {
    return this.slice(0).reverse(...args);
  };

// toSpliced
if (Array.prototype.toSpliced === undefined)
  Array.prototype.toSpliced = function (...args) {
    return this.slice(0).splice(...args);
  };
