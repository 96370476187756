import { useQuery } from '@tanstack/react-query';
import queries from '@queries';
import baseUrl from '@root/config';
import { Loading } from '@components';
import GoogleAuthContext from './GoogleAuthContext';

function GoogleAuthProvider(props) {
  const { children = null, ignore = ['/auth/google/callback', '/error-auth'] } = props;

  const me = useQuery(['me'], () => queries.getAll('/api/users/me', { populate: '*' }), {
    retry: false
  });

  // Get the current url and match with ignore array
  const url = window.location.href;
  const currentPage = url.split('?')[0].split('/').slice(3).join('/');
  const isPageToIgnore = ignore.some(
    (p) =>
      p === url.split('?')[0] || // Match the full url
      p // Match the end path
        .split('?')[0]
        .split('/')
        .filter((c) => c != '')
        .join('/') === currentPage
  );

  if (!isPageToIgnore && me?.isError) window.location.replace(baseUrl + '/api/connect/google');

  return (
    <GoogleAuthContext.Provider value={{ me: me?.data }}>
      {me?.isLoading ? (
        <div className="h-screen">
          <Loading
            src={
              'https://raw.githubusercontent.com/n3r4zzurr0/svg-spinners/main/preview/90-ring-with-bg-black-36.svg'
            }
            className={'animate-spin h-8 dark:invert'}
          />
        </div>
      ) : me?.isSuccess ? (
        children
      ) : (
        <main className="flex flex-col items-center justify-center w-full px-6 py-24 sm:py-64 lg:px-8">
          <p className="text-base font-semibold leading-8 text-indigo-600">403</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Access Denied
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, you don&apos;t have access rights.
          </p>
        </main>
      )}
    </GoogleAuthContext.Provider>
  );
}

export default GoogleAuthProvider;
