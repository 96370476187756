export default {
  DEV: 'http://localhost:1337',
  STAGING: 'https://booking-staging-back.herokuapp.com',
  PRODUCTION: 'https://booking-prod-back.herokuapp.com',
  MC_BOOST: 'https://booking-boost-back-6714b22d0db8.herokuapp.com',
  ASM: 'https://booking-asm-back-8d9783f6b900.herokuapp.com'
  // eslint-disable-next-line no-undef
}[process.env.REACT_APP_ENV || 'DEV'];

export const adminUrls = {
  DEV: 'http://localhost:3001',
  STAGING: 'https://bookingadmin-staging-front.herokuapp.com',
  PRODUCTION: 'https://bookingadmin-prod-front.herokuapp.com',
  MC_BOOST: 'https://reservations.admin.monacoboost.mc',
  ASM: 'https://asmvolley.admin.thoody.com'
  // eslint-disable-next-line no-undef
}[process.env.REACT_APP_ENV || 'DEV'];
