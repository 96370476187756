/* eslint no-prototype-builtins: 0 */
import { useState, useEffect } from 'react';
import TranslatorContext from './TranslatorContext';
import queries from '@queries';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { configToken } from '@queries/utils';
import axios from 'axios';
import baseUrl from '@root/config';

const TranslatorProvider = (props) => {
  const { children = null } = props;
  const [missingTranslations, setMissingTranslations] = useState([]);
  const queryClient = useQueryClient();

  // Get the data
  const { data, isLoading } = useQuery(['configuration'], () =>
    queries.getAll('/api/configuration').then((res) => res?.data)
  );

  // Get the translation dictionnary
  const dict = data?.translate || {};

  // Update the data if new
  const mutationTranslate = useMutation(
    (element) =>
      axios.put(`${baseUrl}/api/configuration/`, { data: { translate: element } }, configToken),
    { onSuccess: () => queryClient.cancelQueries('configuration') }
  );

  const translator = (o) => {
    // If the key matches
    if (dict.hasOwnProperty(o)) return dict[o];

    // If the page has loaded AND the information is missing BUT is not already included
    if (!isLoading && !missingTranslations.includes(o))
      setMissingTranslations((prev) => [...prev, o]);

    return o;
  };

  // We make a mutation only when needed and avoiding concurrency runs
  useEffect(() => {
    if (missingTranslations.length > 0) {
      mutationTranslate.mutate({
        ...dict,
        ...Object.fromEntries(missingTranslations.map((val) => [val, val]))
      });
    }
  }, [missingTranslations]);

  return <TranslatorContext.Provider value={translator}>{children}</TranslatorContext.Provider>;
};

export default TranslatorProvider;
