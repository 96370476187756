/* eslint-disable no-unused-vars*/
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

const HeaderLayout = (props) => {
  const {
    hideEdit = false,
    idThead = '',
    sort = [],
    setSort = () => {},
    config = () => {},
    isSorted = true
  } = props;

  const modifySort = (value) => {
    const indexValue = sort?.findIndex((v) => extractKey(v) === value);
    const direction =
      indexValue >= 0
        ? sort
            ?.find((v) => extractKey(v) === value)
            ?.split(':')
            ?.at(1)
        : null;

    // Get around the three possibility
    const nextValueIndex = (['desc', 'asc', null].indexOf(direction) + 1) % 3;
    const nextValue = ['desc', 'asc', null]?.[nextValueIndex];

    if (nextValue && indexValue >= 0)
      setSort((prev) => prev.toSpliced(indexValue, 1, `${value}:${nextValue}`));
    // Replace the value
    else if (nextValue) setSort((prev) => [...prev, `${value}:${nextValue}`]); // Add the value
    else setSort((prev) => prev.toSpliced(indexValue, 1)); // Remove the value
  };

  // Utils function
  const getConfigKey = (label) => config?.find((c) => c?.label === label)?.key;
  const extractKey = (o) => o?.split(':')?.[0];

  return (
    <thead id={idThead}>
      <tr className="relative table-row">
        {config.map(({ label, classNameColumn }, index) => (
          <th
            key={`table_header_${index}`}
            scope="col"
            className={`sticky top-0 z-10 py-3.5 text-left text-sm font-semibold text-gray-900 transition dark:text-white ${
              index === 0 && 'pl-4 pr-3 sm:pl-0'
            } ${classNameColumn}`}>
            {isSorted ? (
              <a
                href="#"
                className="group inline-flex"
                onClick={() => modifySort(getConfigKey(label))}>
                {label}
                <span
                  className={
                    sort?.map((v) => extractKey(v))?.includes(getConfigKey(label))
                      ? 'ml-2 flex flex-none rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200 dark:bg-white/10 dark:text-white dark:group-hover:bg-white/20'
                      : 'invisible ml-2 flex flex-none rounded text-gray-400 group-hover:visible'
                  }>
                  {sort.length > 1 &&
                    sort?.findIndex((v) => extractKey(v) === getConfigKey(label)) >= 0 && (
                      <span className="ml-1">
                        {sort?.findIndex((v) => extractKey(v) === getConfigKey(label)) + 1}
                      </span>
                    )}
                  {sort?.find((v) => extractKey(v) === getConfigKey(label))?.split(':')?.[1] ===
                  'asc' ? (
                    <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />
                  ) : (
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                  )}
                </span>
              </a>
            ) : (
              <div className="group inline-flex">{label}</div>
            )}
          </th>
        ))}
        {!hideEdit && <th scope="col" className="py-3 pr-6 text-right"></th>}
      </tr>
    </thead>
  );
};

export default HeaderLayout;
