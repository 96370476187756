import _ from 'lodash';

export const flattenKeys = (obj, path = []) =>
  !_.isObject(obj)
    ? { [path.join('.')]: obj }
    : _.reduce(obj, (cum, next, key) => _.merge(cum, flattenKeys(next, [...path, key])), {});

export const addGroupBy = (data, config, groupBy, sort) => {
  // Get the display function to be added
  const displayFnc = config?.find((c) => c?.key === groupBy)?.display;

  // Get the user sorting option
  const sortDirection = sort?.find((s) => s?.split(':')?.[0] === groupBy)?.split(':')?.[1];

  // Order and get all the key
  let dataSorted = _.orderBy(
    data.map((o) => ({ ...o, _groupBy: displayFnc(o) })),
    '_groupBy',
    sortDirection || 'desc'
  );

  const keys = dataSorted.map((o) => o?.['_groupBy']);
  // Get one of each and find their index
  const uniqKeys = _.uniq(keys);
  const indexKeys = uniqKeys.map((key) => dataSorted.findIndex((o) => o?.['_groupBy'] === key));

  // Reverse key and index to start from the end
  const reverseIndexKeys = indexKeys.reverse();
  const reverseUniqKeys = uniqKeys.reverse();

  // Insert element at the right place
  for (let i = 0; i < reverseIndexKeys.length; i++) {
    dataSorted.splice(reverseIndexKeys[i], 0, {
      _title: reverseUniqKeys[i].length > 0 ? reverseUniqKeys[i] : 'Undefined'
    });
  }

  // Remove the key
  return dataSorted;
};
