import { useRef } from 'react';

function InputTextarea(props) {
  const {
    label,
    className = '',
    classNameInput = '',
    name = '',
    onChange = () => {},
    ...rest // To handle all unhandled props so far
  } = props;

  const textRef = useRef();

  const onChangeHandler = (e) => {
    const target = e.target;
    textRef.current.style.height = '30px';
    textRef.current.style.height = `${target.scrollHeight}px`;
  };

  return (
    <div className={'relative ' + className}>
      <label
        htmlFor={name}
        className={'block text-sm font-medium leading-6 text-gray-900 dark:text-white '}>
        {label}
      </label>
      <textarea
        className={`mt-2 w-full resize-none min-h-[20px] rounded-md border-0 dark:bg-white/5 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 dark:focus:ring-emerald-500 sm:text-sm sm:leading-6 ${classNameInput}`}
        name={name}
        id={name}
        ref={textRef}
        rows={1}
        onChange={(e) => {
          onChange(e);
          onChangeHandler(e);
        }}
        {...rest}
      />
    </div>
  );
}

export default InputTextarea;
