const Empty = (props) => {
  const { children = null } = props;

  return (
    <div className="mt-5 relative block w-full rounded-lg border-2 border-dashed border-gray-300 dark:border-gray-500 p-12 text-center focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
      <span className="mt-2 block text-sm font-semibold text-gray-900 dark:text-white">
        {children}
      </span>
    </div>
  );
};

export default Empty;
