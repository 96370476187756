import { useState, useEffect } from 'react';

function Toggle(props) {
  const {
    name = '',
    label = '',
    className = '',
    classNameToggle = '',
    defaultValue = false,
    form = undefined,
    isChecked: isCheckedControlled = false,
    setIsChecked: setIsCheckedControlled = () => {},
    isControlled = false,
    ...rest
  } = props;

  const [isChecked, setIsChecked] = useState(defaultValue);

  // Internal logic to make is more controllable
  const checked = isControlled ? isCheckedControlled : isChecked;
  const setChecked = isControlled ? setIsCheckedControlled : setIsChecked;

  // Update when props is updated
  useEffect(() => setIsChecked(defaultValue), [defaultValue]);

  return (
    <div>
      <label
        htmlFor={name}
        className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
        {label}
      </label>
      <div className={'relative ' + className}>
        <label htmlFor={name} className="inline-flex relative items-center cursor-pointer">
          <input name={name} type="hidden" value={false} form={form} />
          <input
            type="checkbox"
            value={true}
            name={name}
            id={name}
            className="sr-only peer"
            form={form}
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
            {...rest}
          />
          <div
            className={`w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-teal-300 dark:peer-focus:ring-teal-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-teal-600 ${classNameToggle}`}></div>
        </label>
      </div>
    </div>
  );
}

export default Toggle;
