import HeaderLayout from './layout/HeaderLayout';
import TableLayout from './layout/TableLayout';
import TableLoader from './components/TableLoader';
import BodyLayout from './layout/BodyLayout';
import { addGroupBy } from './utils';

const Table = (props) => {
  const {
    data = [],
    onClick = () => {},
    className = '',
    hideEdit = false,
    isLoading = false,
    idTbody = '',
    idThead = '',
    sort = [],
    setSort = () => {},
    config = [],
    groupBy = '',
    isSorted = true
  } = props;

  const dataGrouped = groupBy ? addGroupBy(data, config, groupBy, sort) : data;

  return isLoading ? (
    <TableLoader />
  ) : (
    <>
      <TableLayout className={className}>
        <HeaderLayout
          hideEdit={hideEdit}
          idThead={idThead}
          sort={sort}
          setSort={setSort}
          config={config}
          isSorted={isSorted}
        />

        <BodyLayout
          data={dataGrouped}
          config={config}
          hideEdit={hideEdit}
          onClick={onClick}
          idTbody={idTbody}
        />
      </TableLayout>
    </>
  );
};

export default Table;
