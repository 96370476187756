import { Fragment, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, MoonIcon, SunIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import { Avatar, ContextDarkMode } from '@components';
import { useContext } from 'react';
import { motion } from 'framer-motion';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Header = (props) => {
  const {
    children = null,
    avatar = '',
    tabs = [],
    logo = '',
    logoLink = '/',
    classNameLogo = '',
    userActions = [],
    title = ''
  } = props;

  const [hoverName, setHoverName] = useState('');

  const { updateDarkMode, darkMode } = useContext(ContextDarkMode);

  return (
    <>
      <Disclosure
        as="nav"
        className="bg-white dark:bg-gray-900 fixed w-full z-40 shadow dark:shadow-gray-600">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8  bg-white dark:bg-gray-900 ">
              <div className="relative flex h-16 justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-lg dark:bg-gray-900 py-1 px-3 hover:bg-gray-300 dark:hover:bg-gray-200 dark:text-gray-200 dark:hover:text-gray-900">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex flex-shrink-0 items-center">
                    <Link to={logoLink} className="h-8 flex justify-center items-center">
                      <img src={logo} className={'h-8 ' + classNameLogo} />
                      <h2 className="ml-3 text-lg font-medium">{title}</h2>
                    </Link>
                  </div>
                  <motion.div className="hidden lg:ml-6 sm:flex">
                    {tabs?.map((o, idx) => (
                      <Link
                        key={`links-tab_${idx}`}
                        to={o?.href}
                        onMouseEnter={() => setHoverName(o?.name)}
                        onMouseLeave={() => setHoverName('')}
                        className={classNames(
                          o?.active
                            ? 'border-teal-500 text-gray-900 dark:text-gray-100'
                            : 'border-transparent',
                          'transition flex justify-center items-center sm:px-4 lg:px-6 px-2 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'
                        )}>
                        {o?.icon && o?.icon}
                        <span className={`${o?.icon && 'hidden lg:pl-3'} lg:block`}>{o?.name}</span>
                        {(o?.active || o?.name === hoverName) && (
                          <motion.span
                            className="absolute bottom-0 h-[2px] w-20 bg-gray-500"
                            layoutId="hover"
                            transition={{
                              duration: 0.4,
                              type: 'spring',
                              bounce: 0.3
                            }}></motion.span>
                        )}
                        {o?.active && (
                          <motion.span
                            className="absolute bottom-0 h-[2px] w-20 bg-teal-500"
                            layoutId="underline"
                            transition={{
                              duration: 0.4,
                              type: 'spring',
                              bounce: 0.4
                            }}></motion.span>
                        )}
                      </Link>
                    ))}
                  </motion.div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3 flex lg:ml-1 sm:ml-1 transition">
                    <button
                      onClick={updateDarkMode}
                      className={`cursor-pointer flex self-center gap-0.5 justify-center items-center overflow-hidden text-sm font-medium transition rounded-full p-1 h-8 w-8 text-white bg-gray-500 hover:bg-gray-700 dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300`}>
                      {darkMode ? (
                        <SunIcon className="w-4 h-4" />
                      ) : (
                        <MoonIcon className="w-4 h-4" />
                      )}
                    </button>
                    <Menu.Button className="inline-flex justify-center overflow-hidden text-sm font-medium transition rounded-r-full p-1 text-whit">
                      <span className="sr-only">Open user menu</span>
                      <Avatar>{avatar}</Avatar>
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-800 p-1 shadow-lg dark:shadow-gray-800 border dark:border-gray-600">
                        {userActions?.map((elem, index) => (
                          <Menu.Item key={index}>
                            {({ active }) => (
                              <a
                                href="#"
                                key={index}
                                onClick={(e) => elem?.fnc(e)}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm rounded-md text-gray-700 dark:text-gray-300 dark:hover:text-teal-700 transition'
                                )}>
                                {elem?.title}
                              </a>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Transition
              as={Fragment}
              enter="transform ease-out duration-200 transition"
              enterFrom="translate-y-0 opacity-0"
              enterTo="translate-y-2 opacity-100"
              leave="transition ease-in duration-75"
              leaveFrom="translate-y-2 opacity-100"
              leaveTo="translate-y-0 opacity-0">
              <Disclosure.Panel className="sm:hidden z-40 absolute top-[56px] w-full bg-white dark:bg-gray-900 shadow dark:shadow-gray-700">
                <div className="space-y-1 pt-1 pb-4 border-t dark:border-gray-600">
                  {tabs?.map((o, idx) => (
                    <Disclosure.Button
                      as="a"
                      key={'responsiveTabsIdx_' + idx}
                      href={o?.href}
                      className={classNames(
                        o?.active
                          ? 'bg-indigo-50 dark:bg-gray-600 dark:text-white border-teal-500 text-teal-700 '
                          : 'border-transparent',
                        'flex items-center gap-4 border-l-4 py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 dark:hover:bg-gray-600 dark:hover:text-gray-200 transition'
                      )}>
                      {o?.icon}
                      {o?.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
      <main className="flex-1">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8 pb-5 pt-16">
          {/* Replace with your content */}
          {children}
          {/* /End replace */}
        </div>
      </main>
    </>
  );
};

export default Header;
