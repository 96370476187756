import { useState } from 'react';
import './Button.module.css';

function Button(props) {
  const {
    className = '',
    onClick = (e) => {
      e.preventDefault();
    },
    children = null,
    isLoading = false,
    isWrong = false,
    color = 'blue',
    loaderColor = 'white',
    textColor = 'white',
    ...rest // To handle all unhandled props so far
  } = props;

  const [isAnimated, setIsAnimated] = useState(isWrong);

  return (
    <button
      className={
        className
          ? className
          : `inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-${textColor} bg-${color}-600 hover:bg-${color}-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${color}-500 items-center disabled:opacity-75 disabled:hover:bg-${color}-600 disabled:cursor-not-allowed  ${
              isWrong && isAnimated ? 'animate-wiggle' : ''
            }`
      }
      onMouseDown={() => setIsAnimated(false)}
      onClick={(e) => {
        setIsAnimated(true);
        onClick(e);
      }}
      {...rest}>
      {isLoading && (
        <svg
          className={`animate-spin -ml-1 mr-3 h-5 w-5 text-${loaderColor}`}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
      )}
      {children}
    </button>
  );
}

export default Button;
