import { useContext } from 'react';
import { Sidebar, MobileNavBar } from '@layouts';
import { Configuration, LocalAuth, Translator } from '@context';
import { PowerIcon, MoonIcon, SunIcon } from '@heroicons/react/24/outline';
import { ContextDarkMode } from '@components';
import logo from '@assets/logo.png';

const initialsFromEmail = (email) =>
  email
    ?.split('@')?.[0]
    ?.split('.')
    ?.map((word) => word?.toUpperCase()?.[0])
    ?.join('');

const Layout = (props) => {
  const { links = [], pageName = '', children = null } = props;

  // Loading context
  const { darkMode, updateDarkMode } = useContext(ContextDarkMode);
  const configuration = useContext(Configuration.Context);
  const { me } = useContext(LocalAuth.Context);
  const translator = useContext(Translator.Context);

  // Create the tabs structure
  let linksToRender = links?.map((value) => ({
    name: value?.name || '',
    current: value?.pageName === pageName,
    href: `/${value?.pageName}`,
    icon: value?.icon,
    isTitle: value?.isTitle || false,
    isMobile: value?.isMobile || false
  }));

  // Add the credits only if the config is right
  if (!configuration.enableCredits)
    linksToRender = linksToRender.filter((l) => l.href !== '/credit');

  return (
    <main className="flex flex-1 flex-col lg:flex-row dark:bg-slate-900 h-screen justify-between">
      <Sidebar
        logoLink="/"
        title={translator('Thoody Booking')}
        classNameLogo="h-7"
        className="transition"
        tabs={linksToRender}
        userActions={[
          {
            icon: darkMode ? SunIcon : MoonIcon,
            title: darkMode ? translator('Mode Clair') : translator('Mode Sombre'),
            fnc: updateDarkMode
          },
          {
            icon: PowerIcon,
            title: translator('Déconnexion'),
            fnc: () => {
              window.location.replace('/');
              sessionStorage.removeItem('token');
              localStorage.removeItem('token');
            }
          }
        ]}
        logo={logo}
        avatar={initialsFromEmail(me?.email)}
        username={me?.username}
      />
      {children}
      <MobileNavBar tabs={linksToRender} />
    </main>
  );
};

export default Layout;
