import { useState, useEffect } from 'react';

function DropDown(props) {
  const {
    label = '',
    className = '',
    classNameSelect = '',
    name = '',
    defaultValue = '',
    selectName = '',
    data = [],
    value: valueControlled = '',
    onChange: setValueControlled = () => {},
    isControlled = false,
    ...rest
  } = props;

  const [valueInternal, setValueInternal] = useState(defaultValue);

  useEffect(() => {
    setValueInternal(defaultValue);
  }, [defaultValue]);

  const value = isControlled ? valueControlled : valueInternal;

  return (
    <div className={'relative ' + className}>
      <label
        htmlFor={name}
        className="block text-sm font-medium leading-6 text-gray-900 dark:text-white">
        {label}
      </label>
      <select
        className={`mt-2 block w-full dark:bg-white/5 rounded-md border-0 py-1.5 ${
          value === null ? 'text-gray-400' : 'text-gray-900'
        } dark:text-white sm:text-sm sm:leading-6 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-emerald-600 dark:focus:ring-emerald-500 dark:[&_*]:text-black ${classNameSelect}`}
        value={value}
        onChange={
          isControlled
            ? setValueControlled
            : (e) => {
                setValueControlled(e);
                setValueInternal(e.target.value);
              }
        }
        name={name}
        {...rest}>
        <option hidden>{selectName}</option>
        {data.map((e, index) => (
          <option name={name} key={index} value={e.value}>
            {e.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default DropDown;
