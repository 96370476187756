import React from 'react';

const TableLayout = (props) => {
  const { className, children = null } = props;
  return (
    <div className={className}>
      <table className="min-w-full divide-y divide-gray-300 dark:divide-gray-700">{children}</table>
    </div>
  );
};
export default TableLayout;
