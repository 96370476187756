import safari from './assets/safari.png';
import chrome from './assets/chrome.png';
import share from './assets/share.png';
import add from './assets/add.png';
import add_chrome from './assets/add_chrome.jpeg';

export const iosSteps = [
  {
    name: 'Ouvrez Safari',
    description: (
      <span>
        Ouvrez cette page dans le navigateur Safari <img className="inline h-5" src={safari}></img>
      </span>
    )
  },
  {
    name: 'Ouvrez le menu "partage"',
    description: (
      <span>
        Cliquez sur le boutton <img className="inline h-5" src={share}></img>
      </span>
    )
  },
  {
    name: "Ajoutez sur l'écran d'accueil",
    description: (
      <span>
        Cliquez sur l&apos;option &quot;Sur l&apos;écran d&apos;accueil&quot;{' '}
        <img className="inline h-5" src={add}></img>
      </span>
    )
  },
  {
    name: "Confirmez l'ajout",
    description: 'Confirmez l\'ajout en cliquant sur "Ajouter"'
  }
];

export const androidSteps = [
  {
    name: 'Ouvrez Google Chrome',
    description: (
      <span>
        Ouvrez cette page dans le navigateur Google Chrome{' '}
        <img className="inline h-5" src={chrome}></img>
      </span>
    )
  },
  {
    name: 'Ouvrez le menu "Options"',
    description: (
      <span>
        Cliquez sur le boutton <img className="inline h-5" src={add_chrome}></img>
      </span>
    )
  },
  {
    name: "Ajoutez sur l'écran d'accueil",
    description: "Cliquez sur l'option \"Sur l'écran d'accueil\""
  },
  {
    name: "Confirmez l'ajout",
    description: 'Confirmez l\'ajout en cliquant sur "Ajouter"'
  }
];
