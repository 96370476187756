import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

const InputText = (props) => {
  const {
    label = '',
    className = '',
    classNameInput = '',
    classNameLabel = '',
    subText = '',
    errorText = '',
    name = '',
    placeholder = ' ',
    ...rest // To handle all unhandled props so far
  } = props;

  return (
    <div className={'relative ' + className}>
      <label
        htmlFor={name}
        className={
          'block lg:text-sm font-medium leading-6 text-gray-900 dark:text-white ' + classNameLabel
        }>
        {label}
      </label>
      <input
        type="text"
        className={`peer mt-2 block w-full text-gray-900 border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 text-base leading-7 lg:text-sm lg:leading-6 rounded-md 
        placeholder:text-gray-400 
        dark:bg-white/5 dark:text-gray-50 dark:ring-white/10 dark:focus:ring-teal-500
        disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 
        dark:disabled:text-gray-500 
        focus:ring-2 focus:ring-inset focus:ring-teal-600 
        placeholder-shown:invalid:ring-gray-300 placeholder-shown:invalid:placeholder:text-gray-400 placeholder-shown:invalid:focus:ring-teal-600 placeholder-shown:invalid:text-gray-900
        invalid:text-red-900 invalid:ring-red-300 invalid:placeholder:text-red-300 invalid:focus:ring-red-500 
        dark:invalid:focus:ring-red-500 dark:invalid:text-red-400 dark:invalid:ring-red-700 dark:invalid:placeholder:text-red-300 
        dark:placholder-shown:invalid:focus:ring-white/10 dark:placeholder-shown:invalid:ring-gray-700 dark:placeholder-shown:invalid:placeholder:text-gray-400 dark:placeholder-shown:invalid:focus:ring-teal-500 dark:placeholder-shown:invalid:text-gray-50 dark:[color-scheme:dark] ${classNameInput}`}
        name={name}
        id={name}
        placeholder={placeholder}
        onFocus={(e) => e.currentTarget.select()}
        {...rest}
      />
      <div className="pointer-events-none absolute top-[40px] right-[9px] hidden peer-invalid:peer-placeholder-shown:hidden peer-invalid:block">
        <ExclamationCircleIcon
          className="h-5 w-5 text-red-500 dark:text-red-400"
          aria-hidden="true"
        />
      </div>
      {subText && <p className="mt-2 lg:text-sm text-gray-600 dark:text-gray-400">{subText}</p>}
      {errorText && (
        <p
          className="mt-2 lg:text-sm text-red-600 dark:text-red-400 hidden peer-invalid:peer-placeholder-shown:hidden peer-invalid:block"
          id="email-error">
          {errorText}
        </p>
      )}
    </div>
  );
};

export default InputText;
