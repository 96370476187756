import { motion } from 'framer-motion';
import { useContext } from 'react';
import { Translator } from '@context';

const Section = (props) => {
  const translator = useContext(Translator.Context);
  const {
    title = '',
    description = '',
    children = null,
    buttonLabel = translator('Ajouter'),
    onClick = () => {},
    ignoreButton = false
  } = props;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.1 }}
      className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 w-full overflow-hidden">
      <div className="flex flex-col px-0 lg:px-10 pt-10 mt-4 sm:mt-6 lg:mt-8 h-full dark:bg-slate-900">
        <div className="flex items-center">
          <div className="flex-auto">
            <h1 className="text-xl font-semibold text-gray-900 dark:text-white">{title}</h1>
            <p
              className="mt-2 text-sm text-gray-700 dark:text-gray-500"
              dangerouslySetInnerHTML={{ __html: description }}></p>
          </div>
          {!ignoreButton && (
            <div className="mt-0 ml-16 flex-none">
              <button
                type="button"
                className="inline-flex gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-full bg-zinc-900 py-2 px-3 text-white hover:bg-zinc-700 dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300"
                onClick={() => onClick()}>
                {buttonLabel}
              </button>
            </div>
          )}
        </div>
        {children}
      </div>
    </motion.div>
  );
};

export default Section;
