import { Section, DeleteModal } from '@custom';
import { useState, useContext } from 'react';
import { Table, Modal } from '@components';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import queries from '@queries';
import moment from 'moment';
import { Translator, LocalAuth } from '@context';
import { Layout, Empty } from '@custom';

const headers = (state, me) => [
  {
    key: 'id',
    label: `${state.translator('Id')}`,
    type: 'integer',
    display: (o) => o?.id,
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'room.name',
    label: `${state.translator('Salle')}`,
    type: 'relation',
    display: (o) => o?.room?.name || '',
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'name',
    label: `${state.translator('Description')}`,
    type: 'string',
    display: (o) =>
      (
        <>
          <div className="hidden md:table-cell">{o?.name}</div>
          <div className="table-cell md:hidden">
            <div className="text-gray-900">{o?.name}</div>
            <div className="mt-1 text-gray-500">
              {o?.start ? moment(o?.start).format('DD/MM/YYYY HH:mm') : ''} -
              {o?.end ? moment(o?.end).format(' HH:mm') : ''}
            </div>
          </div>
        </>
      ) || '',
    classNameColumn: 'pl-0 sm:pl-3'
  },
  {
    key: 'start',
    label: `${state.translator('Date de début')}`,
    type: 'datetime',
    display: (o) => (o?.start ? moment(o?.start).format('DD/MM/YYYY HH:mm') : ''),
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'end',
    label: `${state.translator('Date de fin')}`,
    type: 'datetime',
    display: (o) => (o?.end ? moment(o?.end).format('DD/MM/YYYY HH:mm') : ''),
    classNameColumn: 'hidden md:table-cell'
  },
  {
    display: (o) =>
      o?.user?.id === me.id ? (
        <a
          href="#"
          className={`text-red-600 dark:text-red-400 hover:text-red-900 dark:hover:text-red-900`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            state.setElementToDelete(o);
            state.setIsOpen(true);
          }}>
          {state.translator('Supprimer')}
        </a>
      ) : (
        ''
      ),
    classNameColumn: 'text-end'
  }
];

const HomePage = (props) => {
  const { me } = useContext(LocalAuth.Context);
  const translator = useContext(Translator.Context);

  const [sort, setSort] = useState(['start:asc']);
  const [isOpen, setIsOpen] = useState(false);
  const [elementToDelete, setElementToDelete] = useState(null);
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { links = [], pageName = '' } = props;

  const { data, isLoading } = useQuery(['booking', 'me', sort], () =>
    queries
      .getAll('/api/bookings', {
        populate: '*',
        sort: sort,
        filters: {
          end: { $gte: moment().format('YYYY-MM-DDTHH:mm:ss') },
          $or: [{ user: me.id }, { participants: { id: me.id } }]
        }
      })
      .then((res) => res?.data)
  );

  const mutationDeleteEvent = useMutation((data) => queries.delete('/api/bookings', data), {
    onSuccess: () => {
      queryClient.invalidateQueries('booking');
      setIsOpen(false);
    }
  });

  const events = isLoading ? [] : data;

  return (
    <Layout links={links} pageName={pageName}>
      <Section
        title="Evènements à venir"
        description="Liste des prochaines réservations"
        onClick={() => navigate('/create/booking')}>
        {!isLoading && events.length == 0 ? (
          <Empty>{translator('Aucun évenement, ajoutez en pour les voir apparaitre ici.')}</Empty>
        ) : (
          <Table
            className="mt-5 mb-0 sm:mb-3"
            data={events}
            isLoading={isLoading}
            config={headers({ setIsOpen, setElementToDelete, translator }, me)}
            sort={sort}
            setSort={setSort}
            hideEdit={true}
          />
        )}
      </Section>

      <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
        <DeleteModal
          mutation={mutationDeleteEvent}
          setIsOpen={setIsOpen}
          elementToDelete={elementToDelete}
        />
      </Modal>
    </Layout>
  );
};

export default HomePage;
