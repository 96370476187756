import { Link } from 'react-router-dom';
import { Avatar } from '@components';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const SideBar = (props) => {
  const {
    avatar = '',
    tabs = [],
    logo = '',
    logoLink = '/',
    classNameLogo = '',
    userActions = [],
    title = '',
    username = ''
  } = props;

  return (
    <div className="hidden lg:flex shrink-0 w-64 flex-col">
      <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 dark:border-gray-800 bg-white dark:bg-slate-900">
        <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
          <div className="flex flex-shrink-0 items-center px-6">
            <div className="flex items-center">
              <Link to={logoLink} className="h-full flex justify-center items-center">
                <img src={logo} className={'h-8 ' + classNameLogo} />
                <h2 className="ml-3 text-lg font-medium dark:text-gray-400">{title}</h2>
              </Link>
            </div>
          </div>
          <nav className="mt-5 flex-1 space-y-1 bg-white dark:bg-slate-900 px-4">
            {tabs.map((item) =>
              item?.isTitle ? (
                <div key={item.name} className="text-xs font-semibold leading-6 text-gray-400">
                  {item.name}
                </div>
              ) : (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-gray-100 text-emerald-60 dark:bg-slate-700 dark:text-white'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-emerald-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-slate-700',
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                  )}>
                  <item.icon
                    key={item.name}
                    className={classNames(
                      item.current
                        ? 'text-emerald-600 dark:text-white'
                        : 'text-gray-400 group-hover:text-emerald-600 dark:group-hover:text-white',
                      'mr-3 h-6 w-6 flex-shrink-0'
                    )}
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              )
            )}
          </nav>
          <nav className="mt-5 space-y-1 bg-white dark:bg-slate-900 px-4 ">
            {userActions.map((item) => (
              <a
                key={item.name}
                href="#"
                onClick={item.fnc}
                className={classNames(
                  'text-gray-600 hover:bg-gray-50 hover:text-emerald-600 dark:hover:bg-slate-700 dark:text-gray-400 dark:hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                )}>
                <item.icon
                  key={item.name}
                  className={classNames(
                    'text-gray-400 group-hover:text-emerald-600 dark:group-hover:text-white mr-3 h-6 w-6 flex-shrink-0'
                  )}
                  aria-hidden="true"
                />
                {item.title}
              </a>
            ))}
          </nav>
        </div>
        <div className="flex justify-center items-center flex-shrink-0 border-t border-gray-200 dark:border-gray-800 p-4 px-6">
          <a href="#" className="group block w-full flex-shrink-0">
            <div className="flex items-center">
              <div>
                <Avatar>{avatar}</Avatar>
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-gray-700 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white">
                  {username}
                </p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
