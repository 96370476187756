import { useQuery } from '@tanstack/react-query';

import queries from '@queries';

import { Loading } from '@components';
import LocalAuthContext from './LocalAuthContext';
import Login from './Login';

function LocalAuthProvider(props) {
  const {
    ignore = [],
    children = null,
    forgotPassword = '',
    forgotPasswordLabel = 'Forgot Password?',
    register = '',
    registerLabel = 'Not a member yet?',
    registerLinkLabel = 'Register',
    google = null,
    hasRememberMe = false
  } = props;

  const me = useQuery(['me'], () => queries.getAll('/api/users/me', { populate: '*' }));

  // Handling error and reseting token
  if (me.isError && (sessionStorage.getItem('token') || localStorage.getItem('token'))) {
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    window.location.reload();
  }

  // Get the current url and match with ignore array
  const url = window.location.href;
  const currentPage = url.split('?')[0].split('/').slice(3).join('/');
  const isPageToIgnore = ignore.some(
    (p) =>
      p === url.split('?')[0] || // Match the full url
      p // Match the end path
        .split('?')[0]
        .split('/')
        .filter((c) => c != '')
        .join('/') === currentPage
  );

  return me?.isLoading ? (
    <Loading
      src={
        'https://raw.githubusercontent.com/n3r4zzurr0/svg-spinners/main/preview/90-ring-with-bg-black-36.svg'
      }
      className={'animate-spin h-8 dark:invert'}
    />
  ) : isPageToIgnore || (me?.isSuccess && me?.data?.role?.name != 'Pending') ? (
    <LocalAuthContext.Provider value={{ me: me?.data }}>{children}</LocalAuthContext.Provider>
  ) : (
    <Login
      forgotPassword={forgotPassword}
      forgotPasswordLabel={forgotPasswordLabel}
      register={register}
      registerLabel={registerLabel}
      registerLinkLabel={registerLinkLabel}
      google={google}
      hasRememberMe={hasRememberMe}></Login>
  );
}

export default LocalAuthProvider;
