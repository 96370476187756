import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const MobileNavBar = (props) => {
  const { tabs = [] } = props;

  const tabsToDisplay = tabs.filter((t) => t.isMobile);

  return (
    <div className="sticky mt-6 bottom-0 flex shrink-0 lg:hidden h-20 justify-evenly items-center bg-white dark:bg-gray-900 w-full z-40 border-t border-t-1 border-gray-200 dark:border-white/10">
      {tabsToDisplay.map((item) => (
        <Link
          key={item.name}
          to={item.href}
          className={classNames(
            item.current ? 'text-emerald-600 dark:text-white' : 'text-gray-600 dark:text-gray-400',
            'flex flex-col items-center justify center px-2 py-2 text-sm font-medium rounded-md flex-1'
          )}>
          <item.icon
            key={item.name}
            className={classNames(
              item.current ? 'text-emerald-600 dark:text-white' : 'text-gray-400',
              'h-8 w-8 flex-shrink-0'
            )}
            aria-hidden="true"
          />
          {item.name}
        </Link>
      ))}
    </div>
  );
};

export default MobileNavBar;
