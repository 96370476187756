import { useContext } from 'react';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import logo from '@assets/logo.png';

import { Form } from '@layouts';
import baseUrl from '@root/config';

import { Button, InputText, Alert } from '@components';
import { Translator } from '@context';

function ForgotPassword() {
  const identityFunction = (id) => id;
  const translator = useContext(Translator.Context) || identityFunction;
  const loginMutation = useMutation((data) =>
    axios.post(`${baseUrl}/api/auth/forgot-password`, data?.[0])
  );

  return (
    <div className="flex flex-col justify-center items-center h-[100dvh] lg:h-screen dark:bg-gray-900">
      <div className="px-6 lg:px-0 w-screen sm:w-[500px] lg:w-80 lg:overflow-hidden lg:shadow lg:rounded-lg lg:divide-y lg:border lg:dark:bg-gray-800/50 dark:border-gray-700 divide-gray-200  dark:divide-gray-800">
        <div className="px-4 py-5 sm:px-6 flex flex-col items-center justify-center ">
          <img className="w-40 lg:w-28" src={logo} />
          <p className="mt-4 text-xl font-medium lg:font-normal lg:text-base font-montserrat dark:text-white">
            {translator('Thoody Booking')}
          </p>
        </div>
        <Form mutation={loginMutation}>
          <p className="py-6 lg:pb-0 lg:m-auto px-2 text-center lg:text-sm font-montserrat text-gray-500 dark:text-gray-400 w-full">
            {translator(
              'Pour réinitialiser le mot de passe, veuillez renseigner le mail qui correspond à votre compte ou retournez sur la page de '
            )}
            <a
              className="font-montserrat font-semibold leading-6 text-teal-600 hover:text-teal-500 dark:text-teal-400 dark:hover:text-teal-300"
              href={'/'}>
              {translator('Login')}
            </a>
            .
          </p>
          <div className="py-6 sm:p-6 flex flex-col gap-12 lg:gap-4 relative">
            {loginMutation?.isError && (
              <Alert color="red" className="lg:mb-4">
                {translator("L'email renseigné ne semble pas exister.")}
              </Alert>
            )}
            {loginMutation?.isSuccess && (
              <Alert color="green" className="lg:mb-4">
                {translator(
                  "Le mot de passe a été envoyé à l'adresse indiquée, veuillez consulter vos mails pour le réinitialiser."
                )}
              </Alert>
            )}
            <InputText name="email" label={translator('Email *')} required />
            <div className="flex flex-col sm:flex-row-reverse ">
              <Button
                type="submit"
                isWrong={loginMutation?.isError}
                isLoading={loginMutation?.isLoading}
                color=""
                textColor="white"
                onClick={() => {}}
                className={
                  'inline-flex mt-3 gap-0.5 justify-center overflow-hidden text-base lg:text-sm font-medium transition px-3 bg-zinc-900 hover:bg-zinc-700 dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300'
                }>
                {translator('Réinitialiser')}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ForgotPassword;
