import { useQuery } from '@tanstack/react-query';
import { iosSteps, androidSteps } from './config';
import PWAInstallerContext from './PWAInstallerContext';
import axios from 'axios';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function PWAInstallerProvider(props) {
  const { children = null, ignore = [] } = props;

  // Get the current url and match with ignore array
  const url = window.location.href;
  const currentPage = window.location.href.split('?')[0].split('/').slice(3).join('/');
  const isPageToIgnore = ignore.some(
    (p) =>
      p === url.split('?')[0] || // Match the full url
      p // Match the end path
        .split('?')[0]
        .split('/')
        .filter((c) => c != '')
        .join('/') === currentPage
  );

  // Fetch Manifest information
  const { data: manifest, isLoading: manifestIsLoading } = useQuery(['manifest'], () =>
    axios.get(`/manifest.json`).then((res) => res.data)
  );

  // Get the different icons
  const icon = manifestIsLoading
    ? null
    : manifest.icons
        ?.filter((i) => !i.src.includes('favicon'))
        .map((i) => ({ ...i, size: parseInt(i.sizes.split('x')?.[0]) }))
        .toSorted((a, b) => b.size - a.size)?.[0];

  // Get device
  const isIos = ['iPhone', 'iPad'].some((u) => navigator.userAgent.includes(u));

  const isInStandaloneMode =
    window.matchMedia('(display-mode: standalone)').matches ||
    window.navigator.standalone ||
    document.referrer.includes('android-app://');

  const isMobile = ['iPhone', 'iPad', 'Android', 'XiaoMi', 'Huawei'].some((u) =>
    navigator.userAgent.includes(u)
  );

  // Get the steps according to device
  const steps = isIos ? iosSteps : androidSteps;

  return isMobile && !isInStandaloneMode && !isPageToIgnore ? (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-100 px-10">
      <div className="max-w-[200px] shadow-md rounded-3xl bg-white">
        <img className="rounded-3xl" src={`/${icon?.src}`} />
      </div>
      <div className="my-10 flex flex-col justify-center items-center">
        <h1 className="text-base font-semibold leading-6 text-gray-900">{manifest?.short_name}</h1>
        <p className="mt-1 truncate text-sm text-gray-500">{manifest?.name}</p>
      </div>
      <nav aria-label="Progress">
        <ol role="list" className="overflow-hidden">
          {steps.map((step, stepIdx) => (
            <li
              key={step.name}
              className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
              {
                <>
                  <div className="group relative flex items-start cursor-pointer">
                    <span className="flex h-9 items-center" aria-hidden="true">
                      <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-gray-300 group-hover:border-gray-400">
                        <span className="text-gray-500">{stepIdx + 1}</span>
                      </span>
                    </span>
                    <span className="ml-4 flex min-w-0 flex-col">
                      <span className="text-sm font-medium text-gray-500">{step.name}</span>
                      <span className="text-sm text-gray-500">{step.description}</span>
                    </span>
                  </div>
                </>
              }
            </li>
          ))}
        </ol>
      </nav>
    </div>
  ) : (
    // Get if it is PWA
    <PWAInstallerContext.Provider value={isMobile && isInStandaloneMode}>
      {children}
    </PWAInstallerContext.Provider>
  );
}

export default PWAInstallerProvider;
