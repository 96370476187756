// import { Fragment } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import moment from 'moment';
import 'moment/locale/fr';
import { useEffect, useState } from 'react';
// CalendarIcon

moment.locale('fr');

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const capitalize = (str) => str[0].toUpperCase() + str.slice(1);
const daysShort = ['Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa', 'Di'];

export default function DatePicker({ selectedDay = moment(), setSelectedDay = () => {} }) {
  const [days, setDays] = useState(computeMonthGrid(selectedDay));

  useEffect(() => setDays(computeMonthGrid(selectedDay)), [selectedDay]);

  function computeMonthGrid(day) {
    const grid = [];
    // const month = day.month();
    const firstOfMonth = moment(day).date(1);
    const weekDay = firstOfMonth.day();
    const daysToPrepend = weekDay === 0 ? 6 : weekDay - 1;
    grid.push(
      ...Array.from(Array(daysToPrepend).keys())
        .reverse()
        .map((d) => ({
          date: moment(firstOfMonth)
            .subtract(d + 1, 'days')
            .format('YYYY-MM-DD')
        }))
    );

    const dayOfYear = day.dayOfYear();
    const today = moment().dayOfYear();
    grid.push(
      ...Array.from(Array(day.daysInMonth()).keys()).map((d) => {
        const date = moment(day).date(d + 1);
        const DOY = date.dayOfYear();
        return {
          date: date.format('YYYY-MM-DD'),
          isCurrentMonth: true,
          isSelected: dayOfYear === DOY ? true : false,
          isToday: today === DOY ? true : false
        };
      })
    );
    const lastOfMonth = moment(firstOfMonth).date(firstOfMonth.daysInMonth());
    const lastOfMonthWeekDay = lastOfMonth.day();
    const daysToAppend = lastOfMonthWeekDay === 0 ? 0 : 7 - lastOfMonthWeekDay;
    grid.push(
      ...Array.from(Array(daysToAppend).keys()).map((d) => ({
        date: moment(lastOfMonth)
          .add(d + 1, 'days')
          .format('YYYY-MM-DD')
      }))
    );
    return grid;
  }

  return (
    <>
      <div className="flex items-center text-gray-900 dark:text-slate-400  ">
        <button
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 dark:hover:text-white"
          onClick={() => setSelectedDay(moment(selectedDay).subtract(1, 'month'))}>
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-sm font-semibold text-center">
          {capitalize(selectedDay.format('MMMM YYYY'))}
        </div>
        <button
          type="button"
          className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500 dark:hover:text-white "
          onClick={() => setSelectedDay(moment(selectedDay).add(1, 'month'))}>
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500 ">
        {daysShort.map((d, index) => (
          <div className="text-center" key={`day_${index}`}>
            {d}
          </div>
        ))}
      </div>
      <div className="isolate mt-2 grid grid-cols-7 gap-px rounded-lg bg-gray-200 dark:bg-gray-700 text-sm shadow ring-1 ring-gray-200 dark:ring-gray-700 ">
        {days.map((day, dayIdx) => (
          <button
            key={day.date}
            type="button"
            className={classNames(
              'py-1.5 hover:bg-gray-100 focus:z-10 dark:bg-slate-900',
              day.isCurrentMonth ? 'bg-white' : 'bg-gray-50',
              (day.isSelected || day.isToday) && 'font-semibold',
              day.isSelected && 'text-white',
              !day.isSelected &&
                day.isCurrentMonth &&
                !day.isToday &&
                'text-gray-900 dark:text-slate-200',
              !day.isSelected &&
                !day.isCurrentMonth &&
                !day.isToday &&
                'text-gray-400 dark:text-slate-500',
              day.isToday && !day.isSelected && 'text-teal-600',
              dayIdx === 0 && 'rounded-tl-lg',
              dayIdx === 6 && 'rounded-tr-lg',
              dayIdx === days.length - 7 && 'rounded-bl-lg',
              dayIdx === days.length - 1 && 'rounded-br-lg'
            )}
            onClick={() => setSelectedDay(moment(day.date, 'YYYY-MM-DD'))}>
            <time
              dateTime={day.date}
              className={classNames(
                'mx-auto flex h-7 w-7 items-center justify-center rounded-full',
                day.isSelected && day.isToday && 'bg-teal-600',
                day.isSelected && !day.isToday && 'bg-gray-900 dark:bg-slate-700'
              )}>
              {day.date.split('-').pop().replace(/^0/, '')}
            </time>
          </button>
        ))}
      </div>
    </>
  );
}
