import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { useSearchParams, useNavigate } from 'react-router-dom';

import logo from '@assets/logo.png';

import { Form } from '@layouts';
import baseUrl from '@root/config';

import { Button, InputText, Alert } from '@components';
import { useEffect, useContext } from 'react';
import { Translator } from '@context';

function ResetPassword() {
  const identityFunction = (id) => id;
  const translator = useContext(Translator.Context) || identityFunction;
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const resetMutation = useMutation((data) =>
    axios.post(`${baseUrl}/api/auth/reset-password`, data?.[0])
  );

  const code = searchParams.get('code');

  useEffect(() => {
    if (code == null) navigate('/forgot-password');
  }, [code]);

  return (
    <div className="flex flex-col justify-center items-center h-[100dvh] lg:h-screen dark:bg-gray-900">
      <div className="px-6 lg:px-0 w-screen sm:w-[500px] lg:w-80 lg:overflow-hidden lg:shadow lg:rounded-lg lg:divide-y lg:border lg:dark:bg-gray-800/50 dark:border-gray-700 divide-gray-200  dark:divide-gray-800">
        <div className="pb-5 lg:py-5 flex flex-col items-center justify-center">
          <img className="w-40 lg:w-32" src={logo} />
          <p className="mt-4 text-xl font-medium lg:font-normal lg:text-base font-montserrat dark:text-white">
            {translator('Thoody Booking')}
          </p>
        </div>
        <Form
          mutation={resetMutation}
          callback={(r) => {
            sessionStorage.setItem('token', r?.data?.jwt);
            window.location.replace('/');
          }}>
          <p className="py-6 lg:pb-0 lg:m-auto px-2 lg:px-6 text-center lg:text-sm font-montserrat text-gray-500 dark:text-gray-400 w-full">
            {translator('Veuillez saisir un nouveau mot de passe.')}
          </p>
          <div className="py-6 sm:p-6 flex flex-col gap-12 lg:gap-4">
            {resetMutation?.isError && (
              <Alert color="red" className="lg:mb-4">
                {translator('Une erreur est survenue : ')}
                {resetMutation?.error?.response?.data?.error?.message}.
              </Alert>
            )}
            <input type="hidden" name="code" value={code} />
            <InputText
              name="password"
              type="password"
              label={translator('Mot de passe *')}
              required
            />
            <InputText
              name="passwordConfirmation"
              type="password"
              label={translator('Confirmation du mot de passe *')}
              required
            />
            <div className="flex flex-col sm:flex-row-reverse ">
              <Button
                type="submit"
                isWrong={resetMutation?.isError}
                isLoading={resetMutation?.isLoading}
                color=""
                textColor="white"
                onClick={() => {}}
                className={
                  'inline-flex mt-3 gap-0.5 justify-center overflow-hidden text-base lg:text-sm font-medium transition px-3 bg-zinc-900 hover:bg-zinc-700 dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300'
                }>
                {translator('Réinitialiser')}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ResetPassword;
