import JWTAuthContext from './JWTAuthContext';
import { decodeJwt } from 'jose';

function JWTAuthProvider(props) {
  const { children = null, param = 'code' } = props;

  // Get the current url and match with ignore array
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get(param);

  const isAuthorized = token !== null || sessionStorage.getItem('token') !== null;
  const tokenToDecode = token || sessionStorage.getItem('token');

  // Store in storage
  if (token !== null && sessionStorage.getItem('token') !== token) {
    sessionStorage.setItem('token', token);
    window.location.reload();
  }

  // Extract content
  let content = null;

  try {
    content = decodeJwt(tokenToDecode);
  } catch (e) {
    console.log('Error falsified token');
  }

  return (
    <JWTAuthContext.Provider value={content}>
      {isAuthorized ? (
        children
      ) : (
        <main className="flex flex-col items-center justify-center w-full px-6 py-24 sm:py-64 lg:px-8">
          <p className="text-base font-semibold leading-8 text-indigo-600">403</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Access Denied
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Sorry, you don&apos;t have access rights.
          </p>
        </main>
      )}
    </JWTAuthContext.Provider>
  );
}

export default JWTAuthProvider;
