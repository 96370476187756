import { useState, useRef, useEffect } from 'react';
import { ChevronDownIcon, CheckIcon, MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import Badge from './Badge';

// eslint-disable-next-line max-lines-per-function
function MultipleCombobox(props) {
  const {
    label = '',
    name = '',
    defaultValue = [],
    data = [],
    displayValue = () => {},
    displayOptions = () => {},
    filter: filterProps = () => true,
    className = '',
    classNameInput = '',
    classNameLabel = '',
    classNameOptions = '',
    valueKey = (data) => data?.id,
    icon: Icon = MagnifyingGlassIcon,
    value: valueControlled = '',
    setValue: setValueControlled = () => {},
    isControlled = false,
    required = false,
    allowSpace = false,
    noDataLabel = 'No data is corresponding...',
    ...rest
  } = props;

  const [query, setQuery] = useState('');
  const [selectedInternal, setSelectedInternal] = useState(defaultValue);
  const [selectedLi, setSelectedLi] = useState(-1);
  const [paddingText, setPaddingText] = useState('2.5rem');
  const [paddingTop, setPaddingTop] = useState(8);

  const selectedContainer = useRef(null);
  const inputRef = useRef(null);
  const ul = useRef(null);

  // Switch between local or external state
  const selected = isControlled ? valueControlled : selectedInternal;
  const setSelected = isControlled ? setValueControlled : setSelectedInternal;
  const filteredData = data
    .filter((o) => !selected?.map((s) => valueKey(s)).includes(valueKey(o)))
    .filter((o) => (query === '' ? true : filterProps(o, query)));

  const scrollTo = (dir) => {
    const direction = {
      top: Math.max(selectedLi - 1, 1),
      bottom: Math.min(selectedLi + 1, filteredData.length - 1)
    }[dir];

    ul.current.children.item(direction).scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  };

  // Handle keydowns event (ArrownDown 40, ArrowUp 38 , Enter 13, Space 32, Backspace 8)
  const switchOption = (e) => {
    if (
      [40, 38].includes(e.keyCode) ||
      ([13, allowSpace ? '' : 32].includes(e.keyCode) && filteredData?.[selectedLi])
    )
      e.preventDefault();
    if (e.keyCode === 40) {
      setSelectedLi((prev) => Math.min(prev + 1, filteredData.length - 1));
      scrollTo('bottom');
    } else if (e.keyCode === 38) {
      setSelectedLi((prev) => Math.max(prev - 1, -1));
      scrollTo('top');
    } else if ([13, allowSpace ? '' : 32].includes(e.keyCode) && filteredData?.[selectedLi])
      selectData(filteredData?.[selectedLi]);
    else if (e.keyCode === 8 && query.length === 0) removeData(selected.length - 1);
  };

  // Handle the multiple selection
  const selectData = (o) => {
    if (selected?.some((s) => valueKey(s) === valueKey(o))) {
      const indexRemoval = selected?.findIndex((s) => valueKey(s) === valueKey(o));
      removeData(indexRemoval);
    } else setSelected((prev) => [...prev, o]);

    // Empty the query for the next research
    setQuery('');
  };

  // Remove value
  const removeData = (index) =>
    setSelected((prev) => [...prev.slice(0, index), ...prev.slice(index + 1)]);

  // Update Layout Synchronously (otherwise graphical on the first update)
  useEffect(() => {
    // Compute the position of the block (parent), the last child and the last child width to compute the next X position of the curso
    const widthChild = Array.from(selectedContainer?.current?.children)?.at(-1)?.offsetWidth;
    const xPosParent = selectedContainer?.current?.getBoundingClientRect()?.left;
    const xPosChild = Array.from(selectedContainer?.current?.children)
      ?.at(-1)
      ?.getBoundingClientRect()?.left;

    // In order to have the inner padding we do ((last component x pos + last component width) - larger parent x pos) + 5 for visual offset
    setPaddingText(
      isNaN(widthChild + xPosChild - xPosParent + 5) // If children doesn't exist as the input is empty default value is 2.5rem
        ? '2.5rem'
        : widthChild + xPosChild - xPosParent + 5
    );

    // Compute height of the block for both Menu position and input height
    setPaddingTop(Math.max(selectedContainer.current.clientHeight - 22, 8));
  }, [selected, paddingTop]);

  // Update when the defaultValue is updated (after loading for example)
  useEffect(() => {
    if (defaultValue.length > 0) setSelectedInternal(defaultValue);
  }, [defaultValue]);

  // Focus on the input when clicked on the container that is above
  const focusInput = () => {
    inputRef.current.focus();
  };

  return (
    <div className={className}>
      <label
        htmlFor={name}
        className={
          'block text-sm font-medium leading-6 text-gray-900 dark:text-white ' + classNameLabel
        }>
        {label}
      </label>
      {!isControlled && selected.length === 0 && (
        <input type="hidden" name={name} required={required} defaultValue={null} />
      )}
      {!isControlled &&
        selected?.map((s, index) => (
          <input
            key={`hidden_input_${index}`}
            type="hidden"
            name={name}
            defaultValue={valueKey(s)}
            required={required}
          />
        ))}
      <div className="relative">
        <div
          ref={selectedContainer}
          onClick={() => focusInput()}
          className={
            'absolute inset-y-0 flex items-start justify-start flex-wrap h-fit cursor-text ' +
            (Icon ? 'pl-10 ' : 'pl-1')
          }
          style={{ width: 'calc(100% - 36px)' }}>
          {selected?.map((o, index) => (
            <Badge
              className="mr-2 mt-2 flex-shrink-0 p-2 cursor-default  dark:bg-gray-700 dark:text-gray-100"
              key={`badge_${index}`}
              onClick={() => removeData(index)}>
              {displayValue(o)}
            </Badge>
          ))}
        </div>
        {Icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Icon className="h-5 text-gray-400" aria-hidden="true" />
          </div>
        )}
        <div className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 pointer-events-none focus:outline-none">
          <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          ref={inputRef}
          type="text"
          value={query}
          autoComplete="off"
          onKeyDown={(e) => switchOption(e)}
          onChange={(e) => setQuery(e.target.value)}
          onFocus={(e) => {
            e.target.select();
            setSelectedLi(0);
          }}
          style={{ paddingLeft: paddingText, paddingTop }}
          className={`peer mt-2 block w-full leading-6 text-sm dark:bg-white/5 rounded-md border-0 py-1.5 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-white/10 focus:ring-2 focus:ring-inset focus:ring-emerald-600 dark:focus:ring-emerald-500 ${classNameInput}`}
          id={name}
          {...rest}
        />

        <ul
          id={`list_${name}`}
          tabIndex={0}
          ref={ul}
          className={
            'absolute top-11 left-0 z-10 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-gray-800 dark:text-gray-200 shadow-lg ring-1 ring-black dark:ring-gray-700 ring-opacity-5 focus:outline-none text-sm invisible peer-focus:visible hover:visible focus:visible'
          }
          style={{ marginTop: paddingTop }}>
          {filteredData.length === 0 ? (
            <li
              className={
                'group relative cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-teal-600 hover:text-white bg-teal-600 text-white  ' +
                classNameOptions
              }>
              {noDataLabel}
            </li>
          ) : (
            filteredData?.map((o, index) => (
              <li
                key={`option_${index}`}
                onClick={() => selectData(o)}
                className={
                  'group relative cursor-pointer select-none py-2 pl-10 pr-4 hover:bg-teal-600 hover:text-white ' +
                  (selectedLi === index
                    ? ' bg-teal-600 text-white '
                    : 'text-gray-900 dark:text-gray-200') +
                  classNameOptions
                }>
                {displayOptions(o)}
                {selected?.map((s) => valueKey(s)).includes(valueKey(o)) && (
                  <span
                    className={
                      'absolute inset-y-0 left-0 flex items-center pl-3  group-hover:text-white ' +
                      (selectedLi === index ? ' text-white ' : ' text-teal-600 ')
                    }>
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </li>
            ))
          )}
        </ul>
      </div>
    </div>
  );
}

export default MultipleCombobox;
