import { useQuery } from '@tanstack/react-query';
import ConfigurationContext from './ConfigurationContext';
import queries from '@queries';

const ConfigurationProvider = (props) => {
  const { children = null } = props;

  const { data, isLoading } = useQuery(['configuration'], () =>
    queries.getAll('/api/configuration').then((res) => res?.data)
  );

  const configuration = isLoading ? {} : data;

  return (
    <ConfigurationContext.Provider value={configuration}>{children}</ConfigurationContext.Provider>
  );
};

export default ConfigurationProvider;
