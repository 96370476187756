import { Fragment } from 'react';
import { useParams } from 'react-router-dom';

const Render = (props) => {
  const params = useParams();
  const { links = [], paramKey = 'pageName' } = props;

  const SelectedComponent =
    links?.find((o) => o?.pageName == params?.[paramKey])?.render || Fragment;

  return <SelectedComponent links={links} pageName={params?.[paramKey]} />;
};

export default Render;
