import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';

const Modal = (props) => {
  const { isOpen = false, setIsOpen = () => {}, children = null, size = 'sm' } = props;
  const cancelButtonRef = useRef(null);

  const sizeDict = {
    sm: 'sm:max-w-lg  md:max-w-xl lg:max-w-2xl xl:max-w-3xl',
    md: 'sm:max-w-xl  md:max-w-2xl lg:max-w-3xl xl:max-w-4xl',
    lg: 'sm:max-w-2xl  md:max-w-3xl lg:max-w-4xl xl:max-w-5xl',
    xl: 'sm:max-w-3xl  md:max-w-4xl lg:max-w-5xl xl:max-w-6xl',
    '2xl': 'sm:max-w-4xl  md:max-w-5xl lg:max-w-6xl xl:max-w-7xl'
  }[size];

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40" initialFocus={cancelButtonRef} onClose={setIsOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 backdrop-blur-sm transition-opacity  dark:bg-gray-800 dark:bg-opacity-90" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel
                className={`relative transform rounded-lg border bg-white text-left shadow-xl transition-all dark:border-gray-800 dark:bg-gray-900 sm:my-8 sm:w-full ${sizeDict}`}>
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
