import React from 'react';

const BodyLayout = (props) => {
  const { data = [], hideEdit = false, onClick = () => {}, idTbody = '', config = [] } = props;

  return (
    <tbody id={idTbody} className="divide-y divide-gray-200 dark:divide-gray-800">
      {data.map((o, index) =>
        o?._title ? (
          <tr key={`row-${index}`} className={`relative dark:bg-gray-900`}>
            <th
              colSpan={config.length + Number(!hideEdit)}
              scope="colgroup"
              className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 dark:bg-gray-700/40 dark:text-gray-400 sm:pl-3"
              dangerouslySetInnerHTML={{ __html: o?._title ? o?._title : '' }}></th>
          </tr>
        ) : (
          <tr key={`row-${index}`} className="relative">
            {config.map(({ classNameColumn, display }, keyIndex) => (
              <td
                key={`row-${index}-${keyIndex}`}
                className={
                  keyIndex === 0
                    ? ` py-4 pl-4 pr-3 text-sm font-medium text-gray-900 dark:text-gray-100  sm:pl-0 ${classNameColumn}`
                    : ` px-3 py-4 text-sm text-gray-500 dark:text-gray-400 ${classNameColumn}`
                }>
                {display(o, index)}
              </td>
            ))}
            {!hideEdit && (
              <td className="relative py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <a
                  href="#"
                  className={`text-teal-600 hover:text-teal-900 dark:text-teal-400 dark:hover:text-teal-900`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onClick(index, o);
                  }}>
                  Modifier
                </a>
              </td>
            )}
          </tr>
        )
      )}
    </tbody>
  );
};

export default BodyLayout;
