import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import queries from '@queries';
import { Translator, LocalAuth } from '@context';
import { Table } from '@components';
import { Layout, Section, Empty } from '@custom';

const config = (translator) => [
  {
    key: 'groups',
    label: `${translator('Groupes')}`,
    type: 'text',
    display: (o) => o?.groups.map((g, index) => <li key={index}>{g.name}</li>)
  },
  {
    key: 'value',
    label: `${translator('Valeur')}`,
    type: 'number',
    display: (o) => o?.value || ''
  }
];

const Credits = (props) => {
  const translator = useContext(Translator.Context);
  const { me } = useContext(LocalAuth.Context);

  const { links = [], pageName = '' } = props;

  const { data: credits, isLoading: isLoadingCredits } = useQuery(['credits', 'me'], () =>
    queries
      .getAll('/api/credits', {
        populate: '*',
        filters: {
          owner: { id: me.id }
        }
      })
      .then((res) => res.data)
  );

  return (
    <Layout links={links} pageName={pageName}>
      <Section
        title={translator('Mes crédits')}
        description={translator('Liste de mes crédits')}
        ignoreButton={true}>
        {!isLoadingCredits && credits.length == 0 ? (
          <Empty>
            {translator('Aucun crédit, veuillez voir avec un administrateur pour en demander.')}
          </Empty>
        ) : (
          <Table
            className="mt-5 mb-0 sm:mb-3"
            data={credits}
            isLoading={isLoadingCredits}
            config={config(translator)}
            hideEdit={true}
          />
        )}
      </Section>
    </Layout>
  );
};

export default Credits;
