import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Table, Alert } from '@components';
import Button from '@thoody-consulting/button';
import moment from 'moment';
import { Form } from '@layouts';
import { Layout, Empty } from '@custom';
import queries, { displayError } from '@queries';
import Section from '../custom/Section/Section';
import { Translator, LocalAuth } from '@context';

const config = (me, setParticipants, translator) => [
  {
    key: 'username',
    label: `${translator('Username')}`,
    type: 'string',
    display: (o) => o?.username || ''
  },
  { key: 'email', label: `${translator('Email')}`, type: 'email', display: (o) => o?.email || '' },
  {
    display: (o, index) =>
      o?.id === me.id ? (
        <a
          href="#"
          className={`text-red-600 dark:text-red-400 hover:text-red-900 dark:hover:text-red-900`}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setParticipants((prev) => prev.toSpliced(index, 1));
          }}>
          {translator('Supprimer')}
        </a>
      ) : (
        ''
      ),
    classNameColumn: 'text-end'
  }
];

const BookingEdit = (props) => {
  const translator = useContext(Translator.Context);
  const { me } = useContext(LocalAuth.Context);
  const { id } = useParams();
  const navigate = useNavigate();

  const { links = [], pageName = '' } = props;

  const { data: booking, isLoading: isLoadingBooking } = useQuery(
    ['booking', id],
    () => queries.get(`/api/bookings`, id, { populate: '*' }).then((res) => res.data),
    {
      retry: false,
      enabled: !(typeof id === 'undefined' || id === null),
      onSuccess: (data) => setParticipants(data.participants)
    }
  );

  const subscribe = () => {
    const isInList = participants.some((p) => p.id === me.id);

    if (!isInList) setParticipants([...participants, me]);
  };

  const [participants, setParticipants] = useState([]);

  const mutation = useMutation((data) => queries.put(`/api/bookings`, data));

  return (
    <Form mutation={mutation} callback={() => navigate('/')}>
      <Layout links={links} pageName={pageName}>
        <Section
          title={`${translator('Évenement : ')}${booking?.name} (${booking?.room?.capacity})`}
          description={`${translator('Date : ')}${moment(booking?.start).format('DD/MM/YYYY')} - 
            ${translator('Horaires : ')}${moment(booking?.start).format('HH:mm')} - ${moment(
            booking?.end
          ).format('HH:mm')}<br/>
            ${translator('Organisateur : ')}${booking?.user?.username} - 
            ${translator('Salle : ')}${booking?.room?.name}<br/>`}
          buttonLabel={translator("S'inscrire")}
          onClick={() => subscribe()}>
          {mutation?.isError && (
            <Alert color="red" className="mb-4 col-span-full">
              {translator('Une erreur est survenue : ')}
              {displayError(mutation?.error?.response)}
            </Alert>
          )}
          <input type="hidden" name="id" value={id} />
          {participants?.length === 0 && <input type="hidden" name="participants" value={null} />}
          {participants?.map((p, index) => (
            <input
              type="hidden"
              key={`participants_${index}`}
              name="multiple.participants"
              value={p.id}
            />
          ))}

          {!isLoadingBooking && participants.length == 0 ? (
            <Empty>
              {translator('Aucun participant, inscrivez-vous en cliquant sur le boutton.')}
            </Empty>
          ) : (
            <Table
              data={participants}
              className={'col-span-full mt-2 sm:mt-5 pb-12'}
              isLoading={isLoadingBooking}
              config={config(me, setParticipants, translator)}
              hideEdit={true}
            />
          )}
          <div
            className={`pt-6 flex items-center gap-x-6 justify-between border-t border-gray-900/10 dark:border-white/10`}>
            <button
              type="button"
              className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200"
              onClick={() => navigate(-1)}>
              {translator('Annuler')}
            </button>
            <Button
              type="submit"
              className="dark:bg-emerald-400/10 dark:text-emerald-400 dark:ring-1 dark:ring-inset dark:ring-emerald-400/20 dark:hover:bg-emerald-400/10 dark:hover:text-emerald-300 dark:hover:ring-emerald-300"
              isWrong={mutation?.isError}
              isLoading={mutation?.isLoading}
              onClick={() => {}}>
              {translator('Enregistrer')}
            </Button>
          </div>
        </Section>
      </Layout>
    </Form>
  );
};

export default BookingEdit;
