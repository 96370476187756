import { useContext, useState } from 'react';
import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import logo from '@assets/logo.png';

import { Form } from '@layouts';
import baseUrl from '@root/config';
import { configToken } from '@queries/utils';
import { Button, InputText, Alert, CheckBox } from '@components';
import { Translator, Configuration } from '@context';

function Login(props) {
  const {
    forgotPassword = '',
    forgotPasswordLabel = 'Forgot Password?',
    register = '',
    registerLabel = 'Not a member yet?',
    registerLinkLabel = 'Register',
    google = null,
    hasRememberMe = false
  } = props;

  const identityFunction = (id) => id;
  const [rememberMeChecked, setRememberMeChecked] = useState(false);
  const translator = useContext(Translator.Context) || identityFunction;
  const loginMutation = useMutation((data) =>
    axios.post(`${baseUrl}/api/auth/local`, data?.[0], configToken)
  );
  const configuration = useContext(Configuration.Context);
  return (
    <div className="flex flex-col justify-center items-center h-[100dvh] lg:h-screen dark:bg-gray-900">
      <div className="px-6 lg:px-0 w-screen sm:w-[500px] lg:w-80 lg:overflow-hidden lg:shadow lg:rounded-lg lg:divide-y lg:border lg:dark:bg-gray-800 dark:border-gray-700 divide-gray-200  dark:divide-gray-700">
        <div className="pb-5 lg:py-5 flex flex-col items-center justify-center">
          <img className="w-40 lg:w-32" src={logo} />
          <p className="mt-4 text-xl font-medium lg:font-normal lg:text-base font-montserrat dark:text-white">
            {translator('Thoody Booking')}
          </p>
        </div>
        <Form
          mutation={loginMutation}
          callback={(r) => {
            rememberMeChecked
              ? localStorage.setItem('token', r?.data?.jwt)
              : sessionStorage.setItem('token', r?.data?.jwt);
            window.location.reload();
          }}>
          <div className="py-6 sm:p-6 flex flex-col gap-12 lg:gap-4">
            {loginMutation?.isError && (
              <Alert color="red" className="lg:mb-4">
                {translator('Une erreur est survenue : ')}
                {loginMutation?.error?.response?.data?.error?.message}.
              </Alert>
            )}
            <InputText name="identifier" label={translator('Utilisateur')} />
            <div className="relative">
              {forgotPassword && (
                <div className="text-base lg:text-sm absolute top-0 right-0 z-10">
                  <a
                    href={forgotPassword}
                    className="font-semibold text-teal-600 hover:text-teal-500 dark:text-teal-500 dark:hover:text-teal-300">
                    {forgotPasswordLabel}
                  </a>
                </div>
              )}
              <InputText type="password" name="password" label={translator('Mot de passe')} />
            </div>
            {hasRememberMe && (
              <CheckBox
                name="remember-me"
                label="Se souvenir de moi"
                classNameLabel=""
                value={rememberMeChecked}
                onChange={() => setRememberMeChecked(!rememberMeChecked)}
              />
            )}
            <div className="flex flex-col lg:flex-row-reverse ">
              <Button
                type="submit"
                isWrong={loginMutation?.isError}
                isLoading={loginMutation?.isLoading}
                color=""
                textColor="white"
                onClick={() => {}}
                className={
                  'inline-flex items-center mt-3 rounded-md gap-0.5 border border-transparent shadow-sm justify-center overflow-hidden text-base text-white lg:text-sm font-medium transition py-2 px-3 bg-zinc-900 hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-75 disabled:cursor-not-allowed  dark:bg-teal-400/10 dark:text-teal-400 dark:ring-1 dark:ring-inset dark:ring-teal-400/20 dark:hover:bg-teal-400/10 dark:hover:text-teal-300 dark:hover:ring-teal-300'
                }>
                {translator('Se connecter')}
              </Button>
            </div>
          </div>
          {google && (
            <div>
              <div className="relative ">
                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                  <div className="w-full border-t dark:border-gray-700" />
                </div>
                <div className="relative flex justify-center text-sm font-medium leading-6">
                  <span className="bg-white px-6 dark:bg-gray-900 lg:dark:bg-gray-800 text-gray-500 dark:text-gray-400 ">
                    Or continue with
                  </span>
                </div>
              </div>
              <div className="grid grid-cols-1 p-6">
                <a
                  href={google}
                  className="flex w-full items-center justify-center gap-3 rounded-md  px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 hover:bg-gray-50 dark:hover:bg-gray-700 focus-visible:ring-transparent">
                  <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                    <path
                      d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                      fill="#EA4335"
                    />
                    <path
                      d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                      fill="#34A853"
                    />
                  </svg>
                  <span className="text-sm font-semibold leading-6">Google</span>
                </a>
              </div>
            </div>
          )}
        </Form>

        {register && ['direct', 'controlled'].includes(configuration?.registration) && (
          <p className="pt-5 lg:py-5 text-center text-base lg:text-sm font-montserrat text-gray-500 dark:text-gray-400">
            {registerLabel}{' '}
            <a
              href={register}
              className="font-montserrat font-semibold leading-6 text-teal-600 hover:text-teal-500 dark:text-teal-500 dark:hover:text-teal-300">
              {registerLinkLabel}
            </a>
          </p>
        )}
      </div>
    </div>
  );
}

export default Login;
